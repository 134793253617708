:root {
  --primary: #179AD7;
  --secondary: #2656FF;
  --disabled: #99c0fa;
}

@font-face {
  font-family: "poppinslight";
  src: url("../fonts/poppins-light-webfont.woff2") format("woff2"), url("../fonts/poppins-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("../fonts/poppins-medium-webfont.woff2") format("woff2"), url("../fonts/poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("../fonts/poppins-regular-webfont.woff2") format("woff2"), url("../fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsbold";
  src: url("../fonts/poppins-bold-webfont.woff2") format("woff2"), url("../fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  padding: 0;
  font-family: "poppinsregular";
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

button {
  border: 0;
}

.main_header {
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  padding: 5px 0;
  z-index: 9;
}

.navbar-nav .nav-link {
  text-transform: uppercase;
  padding: 8px 15px !important;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.lang_img_bx {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: inline-block;
  /* overflow: hidden; */
}

.lang_img_bx img {
  width: 100%;
  height: 100%;
}

.regsiter_top_btn {
  width: 130px;
  height: 45px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  transition: 0.2s ease-in-out;
}

.regsiter_top_btn:hover {
  background: linear-gradient(116deg, #348cbb 24.52%, #071c68 81.69%);
  color: #fff;
}

.banner_main_sec {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.11) 0%, #ECFCFF 100%);
  padding-top: 80px;
  min-height: 750px;
}

.banner_left_content_box {
  width: 100%;
  max-width: 600px;
  display: inline-block;
}

.banner_left_content_box h1 {
  width: 100%;
  display: inline-block;
  color: #002A42;
  font-family: 'DM Sans', sans-serif;
  max-width: 370px;
  margin-bottom: 15px;
}

.banner_left_content_box p {
  width: 100%;
  display: inline-block;
  line-height: 35px;
  font-size: 17px;
  color: #002A42;
}

.banner_join_btn {
  width: 130px;
  height: 45px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 45px;
  transition: 0.2s ease-in-out;
  display: inline-block;
}

.banner_join_btn:hover {
  background: linear-gradient(116deg, #348cbb 24.52%, #071c68 81.69%);
  color: #fff;
}

.banner_right_img_sec {
  position: absolute;
  right: 0;
  top: 10%;
  width: 100%;
  max-width: 550px;
}

.banner_right_img_bx {
  width: 100%;
  float: right;
  position: relative;
  z-index: 2;
}

.banner_right_img_bx img {
  width: 100%;
}

.banner_right_dot {
  width: 100px;
  height: 270px;
  position: absolute;
  left: 0;
  bottom: 60px;
  z-index: -1;
}

.banner_social_sec {
  width: 100%;
  height: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  max-width: 350px;
}

.banner_social_ico {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #30464B;
  gap: 5px;
  text-transform: uppercase;
  transition: 0.2s ease;
}

.banner_social_ico:hover {
  color: var(--secondary);
}

.banner_social_img {
  width: 18px;
  height: auto;
  display: inline-block;
}

.banner_right_bg {
  width: 400px;
  height: 400px;
  position: absolute;
  left: -80px;
  top: 50px;

}

.banner_right_bg img {
  max-width: 100%;
}

.banner_right_obj_1 {
  width: 70px;
  height: 70px;
  position: absolute;
  left: -400px;
  top: 70px;
  /* transform-origin: top center;
animation: swing 2s ease infinite; */
  animation: hero-thumb-sm-3-animation 7s linear infinite alternate;
}

.banner_right_obj_6 {
  width: 220px;
  height: 200px;
  position: absolute;
  left: -74px;
  top: 80px;
  z-index: 0;
  animation: hero-thumb-sm-2-animation 2s linear infinite alternate;

}

.banner_right_obj_2 {
  width: 70px;
  height: 70px;
  position: absolute;
  left: -50px;
  top: 10px;
  animation: hero-thumb-sm-3-animation 7s linear infinite alternate;
}

.banner_right_obj_3 {
  width: 70px;
  height: 70px;
  position: absolute;
  left: -300px;
  bottom: 70px;
  animation: pulse 1s infinite ease-in-out alternate;
}

.banner_right_obj_4 {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 100px;
  top: 150px;
  z-index: 2;
  animation: pulse 1s infinite ease-in-out alternate;
}

.banner_right_obj_5 {
  width: 200px;
  height: 100px;
  position: absolute;
  right: 70px;
  bottom: 120px;
  z-index: 2;
  animation: hithere 5s ease infinite;
}

.banner_right_obj_7 {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  bottom: 150px;
  z-index: 2;
  animation: hero-thumb-sm-3-animation 7s linear infinite alternate;

}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.2);
  }
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-1deg) scale(1.2);
  }

  50% {
    transform: rotate(2deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes hero-thumb-sm-2-animation {
  0% {
    transform: translateY(30px)
  }

  to {
    transform: translateY(0)
  }
}

@keyframes hero-thumb-sm-3-animation {
  0% {
    transform: translateX(30px)
  }

  to {
    transform: translateX(0)
  }
}

.user_profile_view_sec {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 40px 0;
}

.user_profile_view_box {
  width: 100%;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: #FFF;
  min-height: 50px;
  box-shadow: 0px 21px 74px 0px rgba(0, 0, 0, 0.07);
  padding: 20px;
  margin-top: -90px;
  position: relative;
  z-index: 9;
}

.user_profile_view_frm_row {
  width: 100%;
  display: flex;
  gap: 20px;
}

.user_profile_view_frm_box {
  width: 18%;
  height: auto;
  display: inline-block;
}

.user_profile_view_frm_box_1 {
  width: 25%;
  height: auto;
  display: inline-block;
}

.filter_field {
  border-radius: 30px;
  appearance: auto;
  height: 40px;
}

.advanced_srch {
  color: #000;
  margin-top: 5px;
}

.user_profile_view_frm_box_btn {
  width: 130px;
  height: 45px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 45px;
  transition: 0.2s ease-in-out;
  display: inline-block;
  margin-top: 22px;
}

.user_profile_view_frm_box_btn:hover {
  background: linear-gradient(116deg, #348cbb 24.52%, #071c68 81.69%);
  color: #fff;
}

.best_matches_section {
  width: 100%;
  display: inline-block;
  padding: 60px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDFCFF 100%);
}

#carrousel {
  margin: 10px;
}

@media (max-width: 768px) {
  #carrousel {
    margin: 70px 0px 0px 0px;
  }

  .chat-box .type-area .button-send span {
    display: none;
  }

  .chat-box .type-area .button-send {
    width: auto !important;
    min-width: 48px !important;
  }
}

@media (max-width: 425px) {
  #carrousel {
    margin: 50px 0px 0px 0px;
  }
}

#carrousel .title {
  margin-bottom: 10px;
  font-size: 30px;
}

#carrousel .owl-carousel img {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 300px;
  object-fit: cover;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-shadow: 12px 12px 10px -10px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 12px 12px 10px -10px rgba(0, 0, 0, 0.25);
  box-shadow: 12px 12px 10px -10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

#carrousel .owl-carousel .owl-item {
  height: 400px;
  position: relative;
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  transition: all 0.2s;
  -webkit-transform: all 0.2s;
  z-index: 1;
}

@media (max-width: 768px) {
  #carrousel .owl-carousel .owl-item {
    height: 400px;
  }
}

@media (max-width: 380px) {
  #carrousel .owl-carousel .owl-item {
    height: 200px;
  }
}

#carrousel .owl-carousel .owl-stage-outer {
  padding-bottom: 10px;
}

#carrousel .owl-item.big {
  transform: scale(1);
  -ms-transform: scale(1);
  z-index: 3;
}

#carrousel .owl-item.medium {
  transform: scale(0.8);
  -ms-transform: scale(0.8);
  z-index: 2;
}

#carrousel .owl-item.medium.mdright {
  /*border: solid 1px red;*/
  position: relative;
  left: -30px;
}

@media (min-width: 768px) {
  #carrousel .owl-item.medium.mdright {
    left: -30px;
  }
}

#carrousel .owl-item.medium.mdleft {
  /*border: solid 1px blue;*/
  position: relative;
  right: -30px;
}

@media (min-width: 768px) {
  #carrousel .owl-item.medium.mdleft {
    right: -30px;
  }
}

#carrousel .owl-item.smallRight.active {
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  position: relative;
  left: -80px;
}

#carrousel .owl-item.smallLeft.active {
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  position: relative;
  right: -80px;
}

#carrousel .owl-nav {
  position: absolute;
  top: 40%;
  width: 100%;
}

#carrousel .owl-nav .owl-prev {
  position: absolute;
  left: 0px;
  top: 10px;
  background: transparent !important;
  color: #535353 !important;
  font-size: 20px !important;
}

#carrousel .owl-nav .owl-prev:hover {
  color: #adadad !important;
}

#carrousel .owl-nav .owl-next {
  position: absolute;
  right: 0px;
  top: 10px;
  background: transparent !important;
  color: #535353 !important;
  font-size: 20px !important;
}

#carrousel .owl-nav .owl-next:hover {
  color: #adadad !important;
}

.home_headding_sec {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
}

.home_headding_sec h2 {
  width: 100%;
  display: inline-block;
  color: #002A42;
  font-size: 33px;
  font-family: 'DM Sans', sans-serif;
  margin: 0;
}

.home_headding_sec p {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
  color: #002A42;
  font-family: "poppinslight";
}

.best_match_view {
  margin-top: 40px;
}

.profile_match_crsl_box {
  width: 100%;
  display: inline-block;
  border-radius: 15px;
  border: 5.294px solid #FFF;
  background: #FFF;
  box-shadow: 22.72941px -22.72941px 22.72941px 0px rgba(194, 194, 194, 0.10) inset, 0px 4.23529px 25.41176px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(22.72941017150879px);
  padding: 5px;
}

.profile_match_crsl_box_img {
  width: 100%;
  display: inline-block;
  position: relative;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
}


.profile_match_crsl_box_img img {
  margin: 0 !important;
  border-radius: 5px;
  width: 100%;
  /* transition: 0.2s ease-in-out; */
}

.profile_match_crsl_box_img:hover img {
  /* transform: scale(1.1); */
}

.img_btm_overlay {
  width: 100%;
  min-height: 80px;
  position: absolute;
  border-radius: 0px 0px 10.588px 10.588px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 88.02%);
  z-index: 9;
  left: 0;
  bottom: 0;
}

.profile_btn_row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  position: absolute;
  z-index: 10;
  bottom: 12px;
}

.profile_act_btn {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 22px;
  border: solid 1px #fff;
  transition: 0.2s ease-in-out;
}

.profile_act_btn:hover {
  transform: scale(1.1);
}

.prf_btn_1 {
  border: solid 1px #EDA900;
  color: #EDA900;
}

.prf_btn_2 {
  border: solid 1px #FF3E3E;
  color: #FF3E3E;
}
.prf_btn_6 {
  border: solid 1px #9824d987;
  color: #9824d987;
}

.package_buttons {
  text-align: center;
}

.feature_sticky {
  width: fit-content;
  background-color: #0073cf;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  color: white;
  display: inline;
}

.feature_sticky_outline {
  padding-left: 5px;
  display: inline-flex;
}

.prf_btn_dlt {
  border: solid 1px #c22f2f;
  color: #c22f2f;
  display: inline-block;
}

.prf_btn_edit {
  border: solid 1px #1981d6;
  color: #1981d6;
  display: inline-block;
}

.filter_box_intrstbx_feature {
  position: relative;
  width: auto;
  padding: 5px 10px;
  padding-top: 7px;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
  font-size: 14px;
  color: #242424;
  overflow: hidden;
  background-color: #ececec;
}

.close-icon-feature {
  /* position: absolute; */
  top: 5px;
  right: 5px;
  /* background-color: #a8a8a8; */
  color: #000000;
  border: none;
  border-radius: 50%;
  /* padding: 5px; */
  cursor: pointer;
  font-size: 14px;
  text-align: right;
  padding-bottom: 3px;
}

.prf_btn_3_liked {
  border: solid 1px #FF55A7;
  color: #FF55A7;
}

.prf_btn_4_fvrted {
  border: solid 1px #ffed4f;
  color: #ffed4f;
}

.prf_btn_3 {
  /* border: solid 1px #000000; */
  color: white;
}

.prf_heartbtn_3 {
  border: solid 1px rgb(177, 169, 169);
  color: rgb(177, 169, 169);
}

.prf_btn_4 {
  border: solid 1px #49BEFF;
  color: #49BEFF;
}

.prf_btn_4_travel {
  border: solid 1px #12af12;
  color: #12af12;
}

.chatDefaultpic {
  width: 500px;
  opacity: 0.5;
}

.chatImgdiv {
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.prf_btn_5 {
  border: solid 1px #CF6951;
  color: #CF6951;
}

.total_image_bar {
  width: 100%;
  height: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 2px;
  z-index: 10;
  padding: 0 7px;
  cursor: pointer;
  transition: background-color 0.3s;

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }

  /* Customize the active (selected) bar style */
  .active {
    background-color: blue;
  }
}

.total_image_bar div {
  width: 100%;
  height: 3px;
  background-color: #ffffff79;
}

.profile_cnt_bx {
  width: 100%;
  display: inline-block;
  font-size: 20px;
  color: #000;
  line-height: 25px;
  padding: 5px 1px;
}

.profile_cnt_bx span {
  width: 100%;
  font-size: 14px;
  color: #646464;
  display: inline-block;
}

.our_feature_section {
  width: 100%;
  padding: 40px 0;
  display: inline-block;
}

.our_feature_cnt_sec {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 40px;
}

.our_feature_cnt_sec_box {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 10px;
  text-align: center;
}

.our_feature_cnt_sec_box_ico {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}

.our_feature_cnt_sec_box_ico img {
  max-height: 70px;
}

.our_feature_cnt_sec_box h5 {
  width: 100%;
  display: inline-block;
  color: #002A42;
  font-size: 20px;
}

.our_feature_cnt_sec_box p {
  font-size: 14px;
  color: #646464;
}

.text_video_chat_section {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 25px;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: #FFF;
  box-shadow: 0px 21px 74px 0px rgba(0, 0, 0, 0.07);
  margin-top: 40px;
}

.text_video_chat_section_cnt {
  width: 100%;
  height: auto;
  display: inline-block;
}

.text_video_chat_section_cnt h3 {
  width: 100%;
  color: #002A42;
  font-size: 30px;
  font-family: "poppinsbold";
}

.text_video_chat_section_cnt p {
  width: 100%;
  color: #002A42;
  font-size: 15px;
  line-height: 27px;
}

.text_video_chat_section_cnt ul {
  padding: 0;
  list-style-position: inside
}

.text_video_chat_section_cnt li {
  width: 100%;
  color: #002A42;
  font-size: 17px;
  margin-bottom: 5px;
}

.text_video_chat_section_img {
  width: 100%;
  text-align: center;
}

.text_video_chat_section_img img {
  width: 100%;
  max-width: 350px;
}

.travel_companion_section {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 40px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDFCFF 100%);
}

.travel_companion_cnt {
  width: 100%;
  height: auto;
  display: inline-block;
}

.travel_companion_cnt h3 {
  width: 100%;
  display: inline-block;
  font-size: 30px;
  color: #002A42;
  margin-bottom: 15px;
  font-family: "poppinsbold";
}

.travel_companion_cnt p {
  width: 100%;
  display: inline-block;
  font-size: 15px;
  color: #002A42;
  line-height: 27px;
}

.app_download_section {
  width: 100%;
  display: inline-block;
  background-color: #EFF4FF;
  padding: 60px 0;
  background-image: url(../images/slider-shape.png);
  background-repeat: no-repeat;
}

.app_download_section_cnt {
  width: 100%;
  display: inline-block;
}

.app_download_section_cnt h5 {
  width: 100%;
  display: inline-block;
  color: #002A42;
  font-size: 30px;
  font-family: "poppinsbold";
}

.app_download_section_cnt h5 span {
  width: 100%;
  display: inline-block;
  font-size: 25px;
  font-family: "poppinsregular";
}

.app_download_section_img {
  width: 100%;
}

.app_download_section_img img {
  max-width: 100%;
}

.app_download_section_btn {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 20px;
  transition: 0.2s ease-in-out;
}

.app_download_section_btn img {
  transition: 0.2s ease-in-out;
}

.app_download_section_btn a:hover img {
  transform: scale(1.1);
}

.app_download_view_img {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
}

.app_download_view_img img {
  max-width: 100%;
}

.testimonial_section {
  width: 100%;
  height: auto;
  padding: 40px 0;
  background-color: #fff;
}

.testimonial_section_left_head {
  width: 100%;
  height: auto;
  display: inline-block;
}

.testimonial_section_left_head h5 {
  width: 100%;
  height: auto;
  display: inline-block;
  color: #002A42;
  font-size: 30px;
  font-family: "poppinsbold";

}

.testimonial_section_left_head h5 span {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 20px;
  color: #002A42;
  font-family: "poppinsregular";
}

.testimonial_section_box {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: #FFF;
  box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
}

.testimonial_section_box h5 {
  width: 100%;
  display: inline-block;
  font-size: 20px;
  color: #002A42;
  margin-bottom: 15px;
}

.testimonial_section_box h5 span {
  width: 100%;
  font-size: 14px;
  color: #474747;
  display: inline-block;
}

.testimonial_section_box p {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  color: #666;
  line-height: 26px;
}


.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  display: inline
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.main_footer_section {
  width: 100%;
  height: auto;
  display: inline-block;
  padding-top: 60px;
  background: radial-gradient(768.15% 79.5% at 44.86% 29.46%, rgba(255, 255, 255, 0.11) 0%, #ECFCFF 71.84%, rgba(243, 243, 255, 0.67) 97.42%);
}

.footer_Box {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 10px;
}

.footer_Box_head {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 10px;
}

.footer_Box_cnt {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 14px;
  color: #002A42;
  line-height: 30px;
  margin: 10px 0;
}

.footer_Box_social_sec {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_Box_social_sec_ico {
  transition: 0.2s ease-in-out;
}

.footer_Box_social_sec_ico img {
  width: 100%;
}

.footer_Box_social_sec_ico:hover {
  margin-top: -5px;
}

.footer_Box_head {
  width: 100%;
  height: auto;
  display: inline-block;
  color: #002A42;
  font-size: 18px;
  font-family: "poppinsbold";
}

.footer_Box_cnt ul {
  padding: 0;
}

.footer_Box_cnt li {
  width: 100%;
  display: inline-block;
  list-style: none;
}

.footer_Box_cnt li a {
  text-decoration: none;
  color: #002A42;
  font-size: 14px;
}

.footer_Box_cnt li a:hover {
  color: #179AD7;
}

.footer_copyright_setion {
  width: 100%;
  height: auto;
  display: inline-block;
  border-top: 1px #ECECEC solid;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-top: 20px;
}

.footer_copyright_cnt {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

.footer_copyright_setion_download_btn {
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_copyright_setion_download_btn a {
  width: auto;
  min-width: 120px;
  display: inline-block;
}

.footer_copyright_setion_download_btn a img {
  max-width: 100%;
}

.footer_copyright_cnt span {
  width: 100%;
  height: auto;
  text-align: right;
  color: #002A42;
  font-size: 14px;
}

.main_header.fixed {
  background-color: #fff;
  padding: 0 5px;
  box-shadow: 0px 0px 20px #0000002b;
  z-index: 99;
}

.inner_page_header {
  width: 100%;
  display: inline-block;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.11) 0%, #ECFCFF 100%);
  padding: 120px 0;
}

.inner_page_page_name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner_page_page_name h5 {
  color: #00263A;
  font-size: 30px;
}

.inner_page_page_name span {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

.inner_page_page_name span a {
  text-decoration: none;
  color: #5F5F5F;
  font-size: 15px;
  font-family: "poppinslight";
}

.inner_page_page_name span a:hover {
  color: #000
}


.inner_page_main_bx_section {
  width: 100%;
  height: auto;
  display: inline-block;
  padding-bottom: 40px;
}

.inner_page_main_box_view {
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 20px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3px 44px 0px rgba(0, 0, 0, 0.05);
  margin-top: -80px;
  position: relative;
}

.premium_sts_ico {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 11px;
  top: 10px;
  border-radius: 50px;
  background: #e0f5ff;
}

.premium_sts_ico img {
  width: 100%;
}

.inner_page_main_bx_head {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inner_page_main_bx_head h4 {
  color: #00263A;
  font-size: 24px;
  margin: 0;
}

.inner_page_main_bx_filter_btn {
  width: auto;
  padding: 10px 30px;
  display: flex;
  color: #00263A;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.best_match_section {
  width: 100%;
  display: inline-block;
  padding-top: 10px;
}

.best_match_section .profile_match_crsl_box {
  margin-bottom: 20px;
}

.alert_box_view {
  width: 370px;
  min-height: 40px;
  padding: 5px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  font-size: 13px;
  z-index: 99;
  border-radius: 30px;
  padding-right: 20px;
}

.alert_box_view_cnt {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.alert_box_view_ico {
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  background-color: #fff;
  color: #242424;
  border-radius: 30px;
  line-height: 31px;
}

.alert_box_view_cnt h5 {
  font-size: 16px;
  margin: 0;
}

.alert_success {
  background-color: #1a7711df;
}

.alert_error {
  background-color: #771111dc;
}

.alert_box_view_close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4px;
  top: 30%;
  color: #242424;
}

.loadmore_btn_section {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
  padding: 40px 0;
}

.loadmore_btn {
  width: auto;
  padding: 8px 30px;
  display: inline-block;
  border-radius: 79px;
  border: 1px solid #FFF;
  background: #FFF;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.07);
  color: #242424;
  font-size: 17px;
  border: solid 1px #e5e5e5;
}

.filter_box {
  width: 100%;
  height: auto;
  display: inline-block;
  border-radius: 10px;
  border: 0.5px solid #E8E8E8;
  background: rgba(255, 255, 255, 0.71);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.03);
  padding: 10px;
  margin-bottom: 10px;
}

.filter_box_head {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
}

.filter_box_cnt {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
}

.filter_box select {
  background-color: transparent;
  /* border: 0; */
  border: 1;
  appearance: auto;
  padding-left: 0;
}

.filter_type_slct {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
  padding: 0 5px;
}

.filter_type_slct span {
  width: auto;
}

.filter_type_slct select {
  width: auto;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
 its values is > 50%. */
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top .3s;
  transition: left 0.3s, top .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
* Browsers can paint handles in their own layer.
*/
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
*/
.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  left: -7px;
  top: -7px;
  background-color: #49BEFF;
}

/* Styling;
*/
.noUi-background {
  background: #D6D7D9;
}

.noUi-connect {
  background: #49BEFF;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin {
  border-radius: 2px;
}

.noUi-target {
  border-radius: 2px;
}

.noUi-target.noUi-connect {}

/* Handles and cursors;
*/
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.noUi-handle:active {
  border: 8px solid #49BEFF;
  border: 8px solid rgba(53, 93, 187, 0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px;
}

/* Disabled state;
*/
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.slider-labels .caption {
  font-size: 13px !important;
  margin-top: 5px;
}

.filter_box_intrstbx {
  width: auto;
  padding: 5px 10px;
  padding-top: 7px;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
  font-size: 14px;
  color: #242424;

}


.filter_box_intrstbx:hover {
  border: solid 1px #179AD7;
}

.filter_box_intrstbx.active {
  border: solid 1px #179AD7;
}

.filter_top_btn {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
  cursor: pointer;
}

.filter_top_btn a {
  color: #666;
  font-size: 15px;
  text-decoration: none;
  margin-right: 10px;
  padding: 5px 0px;
}

.filter_top_btn a.active {
  color: #000;
  border-bottom: 2px #179AD7 solid;
}

.profile_page_pro_image {
  width: 100%;
  height: auto;
  display: inline-block;
  max-height: 400px;
  overflow: hidden;
}

.profile_page_pro_image img {
  width: 100%;
}

.profile_page_right_cnt_sec {
  width: 100%;
  display: inline-block;
  padding: 10px;
}

.profile_page_right_name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.profile_page_right_name strong {
  font-size: 30px;
  color: #000000;
}

.profile_page_right_action_btn_sec {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

.profile_page_right_action_btn {
  min-width: 45px;
  height: 45px;
  border-radius: 30px;
  background-color: #e6f6ff;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  color: #242424;
  border: 0;
  transition: 0.2s ease-in-out;
}

.profile_page_right_action_btn:hover {
  background-color: #002A42;
  color: #fff;
}

.prfl_location {
  width: 100%;
  display: inline-block;
  font-size: 20px;
  color: #646464;
}

.profile_right_intrst_sec {
  width: 100%;
  display: inline-block;
  margin-top: 15px;
}

.profile_right_intrst_sec strong {
  width: 100%;
  color: #000;
  font-size: 16px;
}

.profile_right_intrst_cnt_sc {
  width: 100%;
  padding: 5px 0;
  display: inline-block;
}

.profile_page_right_cnt_sec .profile_btn_row {
  position: static;
  justify-content: start;
  margin-top: 20px;
}

.profile_page_right_cnt_sec .profile_act_btn {
  width: 60px;
  height: 50px;
  line-height: 50px;
  font-size: 26px;
}

.profile_page_btm_more_dtl_box {
  width: 100%;
  display: inline-block;
  margin-top: 15px;
  padding: 40px 15px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3px 44px 0px rgba(0, 0, 0, 0.05);
}

.prfile_page_info_sec {
  width: 100%;
  border-radius: 10px;
  border: 1px dashed #E2E2E2;
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
}

.prfile_page_info_head {
  width: auto;
  padding: 5px 20px;
  font-size: 15px;
  background-color: #fff;
  position: absolute;
  top: -18px;
  left: 0;
  font-weight: bold;
}

.prfile_page_info_sec p {
  margin: 0;
  color: #242424;
  font-size: 15px;

}

.prfile_page_photo {
  width: 100%;
  height: auto;
  display: inline-block;
  background-color: #f3f3f3;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.prfile_page_photo img {
  width: 100%;
}

.prfile_page_photo #full-stars-example-two .rating-group {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  justify-content: center;
}

#full-stars-example-two {
  text-align: center;
}

#full-stars-example-two .rating-group {
  display: inline-flex;
}

#full-stars-example-two .rating__icon {
  pointer-events: none;
}

#full-stars-example-two .rating__input {
  position: absolute !important;
  left: -9999px !important;
}

#full-stars-example-two .rating__input--none {
  display: none;
}

#full-stars-example-two .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 1.3rem;
}

#full-stars-example-two .rating__icon--star {
  color: orange;
}

#full-stars-example-two .rating__input:checked~.rating__label .rating__icon--star {
  color: #ddd;
}

#full-stars-example-two .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}

#full-stars-example-two .rating__input:hover~.rating__label .rating__icon--star {
  color: #ddd;
}

.profile_action_btn_sec {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.profile_action_btn {
  width: 100%;
  max-width: 300px;
  display: flex;
  gap: 5px;
  border: solid 1px #e5e5e5;
  padding: 10px;
  border-radius: 10px;
  color: #004F7B;
  text-align: left;
  background-color: #fff;
  min-height: 100px;
  align-items: center;
  transition: 0.2s ease-in;
}

.profile_action_btn:hover {
  background-color: #c7ecff;
}

.profile_action_btn_ico {
  width: 30px;
  font-size: 20px;
}

.profile_action_btn p {
  width: 100%;
  font-size: 13px;
  color: #999999;
  font-family: "poppinslight";
}

.register_login_box {
  width: 100%;
  display: inline-block;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3px 44px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  max-width: 800px;
  border: solid 1px #e5e5e5;

}

.register_login_section {
  width: 100%;
  display: inline-block;
  padding: 40px 0;
  padding-top: 150px;
}

.register_login_box_head {
  width: 100%;
  display: inline-block;
  font-size: 28px;
  color: #242424;
  margin-bottom: 10px;
  font-family: "poppinsmedium";
}

.register_login_box_head p {
  width: 100%;
  font-size: 13px;
  color: #595959;
}

.register_login_box_cnt {
  width: 100%;
  display: inline-block;
  text-align: left;
}

.register_login_box_regbtn {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  border: 0.5px solid #E8E8E8;
  background: rgba(255, 255, 255, 0.71);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.03);
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: auto;
  margin-bottom: 20px;
  transition: 0.2s ease-in-out;
  color: #242424;
}

.register_login_box_regbtn:hover {
  background-color: #d6f1ff;
}

.register_login_box_regbtn_ico {
  width: 47px;
  height: 40px;
  border-right: 1px solid #E7E7E7;
  padding-right: 7px;
}

.already_registrd_reg {
  margin-top: 40px;
}

.register_login_box_cnt_subhead {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 20px;
  color: #00263A;
}

.register_login_box_form {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 10px;
  text-align: left;
}

.register_login_box_form input {
  border-radius: 10px;
  border: 0.5px solid #E8E8E8;
  background: rgba(255, 255, 255, 0.51);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.03);
  /* height: 45px; */
}

.register_login_box_form .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 9px !important;
}

.reg_login_sub_btn {
  width: 130px;
  height: 45px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  transition: 0.2s ease-in-out;
  border: solid 1px #2656ff;
}

.reg_login_sub_btn_disabled {
  width: 130px;
  height: 45px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--disabled) 24.52%, var(--disabled) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgb(255, 255, 255);
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  transition: 0.2s ease-in-out;
  border: solid 1px #ffffff;
}

.register_datebirth_sec {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 10px;
}

.register_datebirth_sec_fld {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 10px;
}

.upload_photo_sec {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 15px;
}

.upload_photo_list {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 15px;
}

.upload_photo_box {
  width: 100%;
  display: inline-block;
  background-color: #f3f3f3;
  border-radius: 5px;
  position: relative;
  height: 150px;
  overflow: hidden;
}

.upload_photo_box img {
  width: 100%;
}

.dlete_phot {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
}

.add_new_photo {
  float: right;
  width: auto;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 13px;
  text-align: center;
  padding: 3px 10px;
}

.btn-primary {
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-files-container {
  width: 100%;
  padding: 30px 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-file-area {
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 100%;
  text-align: center;
  border: solid 1px #e5e5e5;
}

.drag-delete-area {
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 100%;
  text-align: center;
  border: solid 1px #e5e5e5;

}

.drag-delete-area .upload-icon {
  font-size: 25px;
}

.drag-delete-area h3 {
  font-size: 18px;
  margin: 15px 0;
}

.drag-file-area .upload-icon {
  font-size: 30px;
}

.drag-file-area h3 {
  font-size: 18px;
  margin: 15px 0;
}

.drag-file-area label {
  font-size: 19px;
  width: 100%;
}

.drag-file-area label .browse-files-text {
  color: #7b2cbf;
  font-weight: bolder;
  cursor: pointer;
}

.browse-files span {
  position: relative;
  top: -25px;
}

.default-file-input {
  opacity: 0;
  color: transparent;
  /* display: none; */
  cursor: pointer;

}

#file-input {
  /* width: 90px; */
  color: transparent;
}

.cannot-upload-message {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #BB0000;
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cannot-upload-message span,
.upload-button-icon {
  padding-right: 10px;
}

.cannot-upload-message span:last-child {
  padding-left: 20px;
  cursor: pointer;
}

.file-block {
  color: #f7fff7;
  background-color: #7b2cbf;
  transition: all 1s;
  width: 390px;
  position: relative;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.file-icon {
  margin-right: 10px;
}

.file-name,
.file-size {
  padding: 0 3px;
}

.remove-file-icon {
  cursor: pointer;
}

.progress-bar {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 4.5%;
  width: 0;
  height: 5px;
  border-radius: 25px;
  background-color: #4BB543;
}

.upload-button {
  font-family: 'Montserrat';
  background-color: #7b2cbf;
  color: #f7fff7;
  display: flex;
  align-items: center;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  margin: 10px;
  padding: 7.5px 50px;
  cursor: pointer;
}

.login_right_sec {
  width: 100%;
  height: auto;
  display: inline-block;
}

.login_right_sec h2 {
  font-size: 25px;
}

.login_box {
  padding: 30px;
}

.myaccount_profile_section {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 10px;
  text-align: center;
}

.myaccount_profile_top_edit {
  width: auto;
  padding: 5px 20px;
  background-color: #DFF1FF;
  font-size: 13px;
  color: #686868;
  float: right;
  margin-bottom: 20px;
  border-radius: 20px;
  cursor: pointer;
}

.myaccount_profile_top_edit:hover {
  background-color: #004F7B;
  color: #fff;
}

.myaccount_profile_box {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 10px;
  text-align: center;
}

.myaccount_profile_box_img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: solid 2px #fff;
  box-shadow: 0px 0px 20px #ccc;
  margin: auto;
  margin-bottom: 10px;
  position: relative;
}

.myaccount_profile_box_img img {

  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.account-verified {
  width: 32px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
}

.account-verified img {
  width: 100%;
  height: auto;
  border-radius: 0;
}

.myaccount_profile_box_name {
  width: 100%;
  height: auto;
  display: inline-block;
}

.myaccount_profile_box_name .packageEXipry {
  font-size: 15px;
  font-weight: bold;
  color: #3582cf;
  font-family: 'DM Sans';
}

.myaccount_profile_box_name h5 {
  width: 100%;
  font-size: 22px;
  color: #00263A;
  margin: 0;
}

.myaccount_profile_box_name p {
  width: 100%;
  font-size: 14px;
  color: #666;
  margin: 0;
}

.myaccount_profile_upgrad_btn {
  width: auto;
  height: auto;
  padding: 5px 20px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  transition: 0.2s ease-in-out;
  margin-top: 20px;
}

.myaccount_profile_intrestaddbtn {
  width: auto;
  height: auto;
  padding: 5px 20px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  transition: 0.2s ease-in-out;

}

.myaccount_profile_left_navigation {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
  color: #666;

}

.myaccount_profile_left_navigation .active {
  color: #000;
}

.myaccount_profile_left_navigation ul {
  padding: 0;
}

.myaccount_profile_left_navigation li {
  display: inline-block;
  width: 100%;
  list-style: none;
  margin-bottom: 15px;
}

.myaccount_profile_left_navigation li:hover {
  color: #000;
}

.myaccount_profile_left_navigation li a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
  transition: 0.2s ease;
}

.myaccount_profile_left_navigation li a:hover {
  color: #000;
}

.active a {
  color: #179AD7;
}

.prfl_nav_ico {
  width: 25px;
}

.my_account_right_sec {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
}

.my_account_right_head {
  display: inline-block;
  width: 100%;
  color: #00263A;
  font-size: 25px;
  margin-bottom: 15px;
}

.my_account_right_box {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3px 44px 0px rgba(0, 0, 0, 0.05);
  padding: 15px;
}

.my_account_right_box_noti {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3px 44px 0px rgba(0, 0, 0, 0.05);
  padding: 15px;
  overflow: auto;
  max-height: 500px;
}

.my_account_right_box .tab-content {
  border-top: 0 !important;
}

.my_account_right_box .nav-link {
  color: #666;
}

.account_page .inner_page_main_box_view {
  margin-top: -25%;
  height: 113%;
}

.short_term_slct {
  background-color: #DEF9FF !important;
  border-radius: 20px;
}

.filter_hd2 .filter_box_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: auto;
}

.toggle_btn_type input {
  display: none;
}

.toggle_btn_type label {
  position: relative;
  cursor: pointer;
  display: block;
  width: 50px;
  height: 30px;
  background: #cccccc;
  border-radius: 50px;
  transition: 300ms linear;
}

.toggle_btn_type label:before {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background: #ffffff;
  border-radius: 50%;
  transition: left 300ms linear;
}

.toggle_btn_type input:checked+label {
  background: #000000;
}

.toggle_btn_type input:checked+label:before {
  left: 23px;
}

.toggle_btn_type {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;

}

.filter_hd2 .filter_type_slct span {
  width: 50%;
}

.filter_hd2 .filter_type_slct span input {
  border: 0;
  border-bottom: 1px #ccc solid;
}

.my_account_right_sec .filter_box {
  height: 95%;
}

.premium_plan_info {
  width: auto;
  padding: 5px 10px;
  background-color: #179AD7;
  color: #fff;
  border-radius: 20px;
}

.message_top_users_view {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  overflow: auto;
  border-bottom: 2px #e5e5e5 solid;
}

.message_top_users_box {
  width: 120px;
  min-width: 120px;
  height: auto;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.message_top_users_box_img {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 50px;
  overflow: hidden;
  border: solid 3px #fff;
  position: relative;
}

.message_count {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 18px;
  background-color: #004F7B;
  color: #fff;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  border-radius: 30px;
  z-index: 9;
}

.message_top_users_box_img img {
  width: 100%;
}

.message_top_users_box strong {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #242424;
  font-weight: normal;
}

.online_user {
  border: solid 3px #12af12;
}

.chat_area_sec {
  width: 100%;
  display: inline-block;
  margin-top: 20px;
}

.chat-box {
  width: 100%;
  margin: auto;
  position: relative;
  border: solid 1px #e5e5e5;

}

.chat-box .header {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 15px;
}

.chat-box .header .avatar-big {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50px;
}

.chat-box .header .name {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 17px;
}

.chat-box .header .name p {
  margin: 0;
}

.chat-box .header .options {
  font-size: 20px;
  color: lightgrey;
  margin-left: auto;
  cursor: pointer;
}

.chat-box .chat-room {
  height: 300px;
  background-color: #f7f9fb;
  padding: 15px;
  overflow: auto;
}

.chat-box .chat-room .avatar-small {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  overflow: hidden;
}

.chat-box .chat-room .message {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.chat-box .chat-room .message-left {
  align-items: flex-start;
}

.chat-box .chat-room .message-left .bubble {
  border-radius: 0 5px 5px 5px;
}

.chat-box .chat-room .message-right {
  align-items: flex-end;
}

.chat-box .chat-room .message-right .bubble {
  border-radius: 5px 0 5px 5px;
}

.chat-box .chat-room .bubble {
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;
  display: inline-block;
}

.chat-box .chat-room .bubble-light {
  background-color: #a9e0ff;
}

.chat-box .chat-room .bubble-dark {
  color: #fff;
  background-color: #b4c9d5;
}

.chat-box .type-area {
  display: flex;
  height: 65px;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  width: 99%;
  gap: 10px;
}

.chat-box .type-area .input-wrapper {
  overflow: hidden;
  border-radius: 0 0 0 20px;
  width: 85%;
  position: relative;
}

.chat-box .type-area .input-wrapper input {
  outline: none;
  border: none;
  padding-left: 60px;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

.chat-emoji-btn {
  width: 50px;
  min-width: 50px;
  height: 60px;
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 29px;
  color: #666;
  background-color: #fff;
}

.chat-box .type-area .button-add {
  display: flex;
  align-items: center;
  position: relative;
}

.chat-box .type-area .button-add i {
  font-size: 30px;
  color: lightgrey;
  cursor: pointer;
}

.chat-box .type-area .button-add i:hover {
  color: #be79df;
}

.chat-box .type-area .button-add .others {
  display: flex;
  padding: 10px 15px;
  background-color: #fff;
  position: absolute;
  top: 5px;
  z-index: -1;
  right: -52px;
  border-radius: 999px;
  box-shadow: 0 0 25px -5px lightgray;
  transition: 0.3s all ease-out;
}

.chat-box .type-area .button-add .others span i {
  font-size: 20px;
  color: #be79df;
}

.chat-box .type-area .button-add .others span.image-button {
  margin: 0 25px;
}

.chat-box .type-area .button-add .others .emoji-button {
  position: relative;
}

.chat-box .type-area .button-add .others .emoji-button .emoji-box {
  display: none;
  position: absolute;
  width: 0px;
  height: 0px;
  top: 0px;
  right: 0px;
  transition: 0.3s all ease-out;
}

.chat-box .type-area .button-add .others .emoji-button .emoji-box span {
  user-select: none;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
}

.chat-box .type-area .button-send {
  margin-left: auto;
  width: 80px;
  min-width: 80px;
  height: 45px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  margin-top: 10px;
}

.chat-box .type-area .button-send:hover {
  background-color: #be79df;
  color: #fff;
}

aside.EmojiPickerReact.epr-main {
  position: absolute !important;
  bottom: 65px !important;
  /* width: 100% !important; */
}

.my_account_right_sec .profile_match_crsl_box_img {
  height: 200px;
  transition: 0.2s ease-in-out
}

.my_account_right_sec .profile_match_crsl_box_img:hover {
  transform: scale(1.1);

}

.dlete_action_btn {
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #000;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
  border-radius: 50px;
  cursor: pointer;
}

.formError {
  font-size: small;
  color: rgb(211, 19, 19);
  margin-top: 1px;
}

.selectedFile {
  /* background: #a66fa626;
  font-size: medium;
  padding: 5px;
  border-radius: 5px; */
  background: #a66fa626;
  padding: 4px 10px 1px;
  border-radius: 5px;
  font-size: 15px;

}



.earnpoint_box {
  width: 100%;
  display: inline-block;
}


.earnpoint_box h3 {
  width: 100%;
  display: inline-block;
  color: #002A42;
  font-size: 33px;
  font-family: 'DM Sans', sans-serif;
  margin: 0;
  margin-bottom: 20px;
}

.about_box h3 span {
  width: 100%;
  font-size: 14px;
  display: inline-block;
}

p {
  font-size: 15px;
  color: #002A42;
  line-height: 27px;
}

.earnpoint_box_option {
  width: 100%;
  display: inline-block;
  margin-top: 30px;
}



.earnpoint_box_option ul {
  padding: 0;
}

.earnpoint_box_option li {
  padding-left: 40px;
  height: auto;
  display: inline-block;
  font-size: 18px;
  color: #242424;
  position: relative;
  font-weight: bold;
}

.earnpoint_box_option li:before {
  width: 25px;
  height: 25px;
  content: "\f00c";
  border-radius: 50px;
  background-color: #002A42;
  color: #fff;
  position: absolute;
  left: 0;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900)
}

.earnpoint_box_option li p {
  font-weight: normal;
}

.about_vision_mission_sec {
  width: 100%;
  padding: 40px 0;
  display: inline-block;
  border-top: 1px #e5e5e5 solid;
}

.about_vision_mission_box {
  width: 100%;
  height: 95%;
  margin-bottom: 20px;
  border-radius: 5px;
  /* border: 1px dashed #e2e2e2; */
  background: #FFF;
  /* box-shadow: 0px 21px 74px 0px rgba(0, 0, 0, 0.07); */
  padding: 15px;
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
}

.about_vision_mission_box_ico {
  width: 250px;
  display: inline-block;
}

.about_vision_mission_box_ico img {
  width: 100%;
}

.about_vision_mission_box h5 {
  width: 100%;
  display: inline-block;
  font-size: 22px;
  color: #242424;
  margin-bottom: 10px;
}

.about_vision_mission_box p {
  width: 100%;
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  color: #242424;
  margin-bottom: 0px;
}

.contact_lft_box {
  width: 100%;
  display: inline-block;
}

.contact_lft_box ul {
  padding: 0;
}

.contact_lft_box li {
  list-style: none;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.contact_lft_box li a {
  width: 100%;
  display: inline-block;
  font-size: 17px;
  color: #242424;
}

.contact_right_box {
  width: 100%;
  display: inline-block;
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 20px #f3f3f3;
  text-align: left;
}

.contact_right_box h5 {
  width: 100%;
  display: inline-block;
  font-size: 25px;
  color: #131313;
  margin-bottom: 15px;
}

.contact_right_box .regsiter_top_btn {
  display: inline-block;
  line-height: 45px;
}

.inner_page_cnt_option_two {
  width: 100%;
  display: inline-block;
  padding: 60px 0;
}

.help_page h1 {
  font-weight: 800 !important;
  font-size: 40px;
}

.help_page h6 {
  color: #716f6f
}

.help_page .img-wrapper {
  max-width: 500px;
}


.help_page input,
.help_page textarea,
.help_page select {
  background: #f7f9f8 !important;
}

.help_page .email-us {
  text-decoration: underline;
}

.help_pagelabel {
  font-weight: 600;
  color: #716f6f
}

.help_page button {
  padding: 10px 30px;
}

.help_page button span {
  margin-left: 8px;
}

.help_page button i {
  margin: 0px 8px;
}

.help_page .form-group {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}

.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}

.accordion .accordion-item button[aria-expanded=true] {
  border-bottom: 1px solid #03b5d2;
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #7288a2;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: #03b5d2;
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: #03b5d2;
  border: 1px solid #03b5d2;
}

.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded=true] {
  color: #03b5d2;
}

.accordion button[aria-expanded=true] .icon::after {
  width: 0;
}

.accordion button[aria-expanded=true]+.accordion-content {
  opacity: 1;
  max-height: 9em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}

.privacy_poicy_cnt {
  width: 100%;
  display: inline-block;
}

.privacy_poicy_cnt h2 {
  width: 100%;
  display: inline-block;
  font-size: 22px;
  color: #000;
}

.privacy_poicy_cnt p {
  width: 100%;
  display: inline-block;
  font-size: 15px;
  color: #242424;
  line-height: 30px;
  margin-bottom: 15px;
}

.erorInput {
  border: 0.5px solid #E12626A1 !important;
  border-radius: 10px !important;
  background: rgba(255, 255, 255, 0.51);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.03);
  /* height: 45px; */
}

.Toastify__progress-bar--success {
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%) !important;
}

.toastMsg {
  font-weight: bolder;
  text-transform: capitalize;
}

.Toastify__progress-bar--error {
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%) !important;
}

.popupButtonArea {
  background: linear-gradient(116deg, #3794bf 24.52%, #3759cf 81.69%);
  width: max-content;
  padding: 3px 10px;
  border-radius: 30px;
  text-align: center;
  position: fixed;
  bottom: 2%;
  z-index: 1111;
  right: 0px;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2em;


}

/* .popupButtonArea @media (min-width: 1400px) {
  width: 25%;
} */

.popupButtonArea ul {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

.popupButtonArea li {
  display: inline-flex;
  margin-right: 15px;
  color: #fff;
  font-size: 18px;

  span i {
    cursor: pointer;
  }
}

.popupButtonArea .CountRound {
  background-color: #3c3c4c;
  width: 38px;
  height: 24px;
  padding: 11px;
  border-radius: 13px;
  color: #fff;
  font-size: 16px;
  display: block;
  line-height: 5px;
  margin-right: 15px;
  font-weight: 600;
}

.popupButtonArea .spanText {
  height: 24px;
  padding: 11px;
  color: #fff;
  font-size: 16px;
  display: block;
  line-height: 1px;
  margin-right: 15px;
}

.popupButtonArea .CancelButton {
  background-color: #bbebff !important;
  border: none;
  border-radius: 30px;
  padding: 9px 15px;
  color: #000;
  font-size: small;
  margin: 5px;
}

.popupButtonArea .CancelButton:hover {
  background-color: #fff !important;
}

.ageInput {
  border: none;
  border-bottom: 1px solid #d7c7c7;
}

.editProfilepic {
  /* position: absolute;
  right: 20px;
  z-index: 1;
  bottom: 0;
  color:black;
  cursor: pointer; */
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  padding-left: 5px;
  padding-top: 3px;
  bottom: -10px;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-radius: 29px;
  background-color: #2691ff;
  color: #fff;
  opacity: 0.7;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.profile_image {
  position: relative;
}

.arrow-left-arrow {
  /* background:transparent;
  color: transparent; */
  color: #000;
  position: absolute;
  top: 49%;
  left: 24px;
  cursor: pointer;
  transform: translate(-50%, -50%);
  opacity: 0;
  /* transition: 0.2s ease-in-out */

}

.arrow-left-arrow:hover {
  color: white;
  opacity: 0.5;
  /* transform: scale(1.1); */
}

.arrow-right-arrow {
  /* background:transparent;
  color: transparent; */
  color: #000000;
  position: absolute;
  cursor: pointer;
  top: 49%;
  right: 24px;
  transform: translate(-50%, -50%);
  opacity: 0;
  /* transition: 0.2s ease-in-out */
}

.arrow-right-arrow:hover {
  color: white;
  opacity: 0.5;
  /* transform: scale(1.1); */
}

.logined_user_prfl {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.logined_user_prfl_img {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: solid 1px #e5e5e5;
  overflow: hidden;
}

.logined_user_prfl_txt {
  color: #000;
}

.logined_user_prfl_img img {
  max-width: 100%;
}

.user_profile_top .dropdown-toggle::after {
  position: absolute;
  right: 0;
  top: 20px;
}

.user_prfl_top_hdr {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.usr_prfl_box {
  width: 25px;
  height: 25px;
  border-radius: 30px;
  background-color: #caddeb;
  text-align: center;
  overflow: hidden;
}

.usr_prfl_box img {
  width: 100%;
  height: 100%;
}

.dropdown-menu .user_prfl_top_hdr {
  padding: 10px;
  padding-top: 0;
  border-bottom: 1px #e5e5e5 solid;
}

.dropdown-menu {
  box-shadow: 0px 0px 10px #0000001f;
  border: 0;
}

.notification_ico span {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f00;
  border-radius: 30px;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  color: #fff;
}

.notification_drp_sec {
  min-width: 250px;
  right: 0;
  left: inherit;
}

.notification_btn::after {
  display: none;
}

.notification_drp_sec li {
  padding: 5px 10px;
  line-height: 15px;
}

.notification_list_row_new {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.notification_drp_sec p {
  width: 100%;
  display: inline-block;
  margin-bottom: 0;
}

.notification_drp_sec li span {
  width: 100%;
  display: inline-block;
  font-size: 12px;
  color: #555;
}

.view_all_btn {
  padding-top: 12px !important;
  width: 100%;
  display: inline-block;
  border-top: 1px #e5e5e5 solid;
}

.view_all_btn a {
  color: #242424;
  font-size: 14px;
}

.notification_scrl {
  width: 100%;
  display: inline-block;
  max-height: 250px;
  overflow: auto;
}

.notification_list {
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

.notification_list_row {
  width: 100%;
  /* display: inline-block; */
  display: -webkit-inline-box;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px #e5e5e5 solid;
  gap: 5px;
}

.notification_list_row:last-child {
  border: 0;
  margin: 0;
}

.notification_list_row p {
  width: 100%;
  display: inline-block;
  font-size: 18px;
  color: #242424;
  font-weight: 600;
  margin-bottom: 0;
}

.notification_list_row span {
  font-size: 14px;
  color: #555;
  width: 100%;
}

.clear_all_btn {
  float: right;
  font-size: 13px;
  float: right;
  background-color: #fff;
  padding: 5px 10px;
}

.error_page_sec {
  width: 100%;
  display: inline-block;
  padding: 40px 0;
  padding-top: 120px;
  text-align: center;
}

.error_page_img {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.error_page_img img {
  max-width: 500px;
  width: 100%;
}

.error_page_sec p {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 22px;
  color: #242424;
}

.back_home_btn {
  width: auto;
  padding: 10px 30px;
  background-color: #fff;
  color: #00263A;
  border: solid 1px #95b0c0;
  font-size: 15px;
  text-decoration: none;
  display: inline-block;
  border-radius: 30px;
  font-weight: 600;
  margin-top: 30px;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  /* Adjust the height as needed */
}

.center-line {
  flex: 1;
  border-top: 1px solid #d3d3d3;
  /* Customize the line's appearance */
}

.center-text {
  padding: 0 10px;
  /* Optional: Adjust padding for spacing around the text */
}

.login_google_ico:hover {
  cursor: pointer;
  /* Change the cursor to a pointer when hovering */
}

.login_google_ico {
  border: solid 2px #ccc;
  border-radius: 50%;
}

.usr_prfl_box_noti {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: #caddeb;
  text-align: center;
  overflow: hidden;
}

.locationList {
  cursor: pointer;
}

.locationList-container {
  max-height: 0;
  overflow-y: scroll;
  transition: max-height 0.3s ease-in-out;
  /* Adjust the duration as needed */
}

.locationList-container.open {
  max-height: 200px;
  position: absolute;
  left: 0;
  top: 37px;
  width: 100%;
  z-index: 99;
  background: #fff;
  border-bottom: solid 1px #ccc;
  /* Adjust the height as needed */
}

.loaction_name {
  text-transform: capitalize;
}

.location_img_btm_overlay {
  width: 100%;
  min-height: 80px;
  position: absolute;
  border-radius: 0px 0px 10.588px 10.588px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #ada4a4 88.02%);
  z-index: 9;
  left: 0;
  bottom: 0;
}

.travel-partner {
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.travel_dtl_pop_photo {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  border: solid 5px #fff;
  box-shadow: 0px 0px 30px #c7c7c7;
  margin-bottom: 20px;
}

.travel-partner {
  margin-bottom: 10px;
}

.travel_pop_dtl {
  font-size: 17px;
}

/* -- upgrade -- */
.inner_page_main_bx_section {
  width: 100%;
  height: auto;
  display: inline-block;
  padding-bottom: 40px;
}

.upgrade_page_head {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
  text-align: center;
}

.upgrade_page_head h2 {
  color: #000;
  font-weight: bold;
}

.upgrade_page {
  padding: 60px 0;
}

.upgrade_page_head {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
  text-align: center;
}

.upgrade_page_head h2 {
  color: #000;
  font-weight: bold;
}

.plan {
  background: white;
  border-radius: 24px;
  padding: 24px;
  color: #181059;
  font-family: "Inter", sans-serif;
  transition: all 0.2s;
  border: solid 3px #e5e5e5;
}

.plan:hover {
  background: #eeebff;
  border: solid 3px #181059;
  /* color: #ffffff; */
}

.plan-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
}

.plan-image img {
  max-width: 100%;
  mix-blend-mode: darken;
}

.plan-heading {
  font-size: 27px;
  font-weight: bold;
  color: #0073cf;
}

.plan-feature {
  list-style: none;
  padding: 0px;
}

.plan-feature li:before {
  content: "✓";
  margin-right: 16px;
}

/* .plan-feature li:last-child:before {
    content: "✖";
  } */

.plan-feature li {
  margin-bottom: 16px;
  display: flex;
}

/* .plan-feature li:last-child {
    opacity: 0.3;
  } */

.plan-amount {
  font-size: 24px;
  font-weight: bold;
}

.edit_icon_mail {
  text-align: right;
}

.edit_content_warning {
  color: #bb3c1f;
}

.plan-duration {
  opacity: 0.6;
}

.plan-choose {
  width: 100%;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7px 17px 18px #00000017;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 20px;
  padding: 12px;
  font-weight: bold;
}


.plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.plan-save {
  background: white;
  border: none;
  padding: 5px;
  border-radius: 4px;
  color: #8645ff;
  font-weight: bold;
}

/* .plan:first-child .plan-feature li {
    opacity: 0.3;
  } */

.plan:first-child .plan-feature li:first-child {
  opacity: 1;
}

/* .plan:first-child .plan-feature li:nth-child(n + 2):before {
    content: "✖";
  }
   */
/* .plan:nth-child(2) .plan-feature li:nth-child(n + 3) {
    opacity: 0.3;
  } */

.plan:nth-child(4) .plan-feature li {
  opacity: 1;
}

.plan:nth-child(4) .plan-feature li:before {
  content: "✓";
}

.plan-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.plan:hover {
  transform: scale(1.1);
}

.admin_settings_header {
  padding-bottom: 40px;
}

/* .plan:hover .plan-heading{color: #fff;}
  .plan:hover p{color: #fff;} */

.paypal {}

.upgrade_package {}

.EmojiPickerReact .Flex.FlexRow {
  display: none;
}

.payment_now_section {
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
}

.payment_now_section .paypal {
  width: 100%;
  margin: auto;
  max-width: 600px;
  border: solid 2px #e5e5e5;
  padding: 30px 60px;
}

.payment_now_section .regsiter_top_btn {
  display: inline-block;
  margin-top: -24px;
  margin-bottom: 50px;
}

.payment_now_section h2 {
  margin-bottom: 20px;
}

.chatTime {
  float: right;
  font-size: small;
}

.stickercontainer {
  max-height: 440px;
  overflow-y: auto;
}

.testimonialErr {
  border-color: red;
}

.travel-request-accept {
  color: green;
  font-weight: bold;
  margin-top: 1em;
}

.travel-request-reject {
  color: #c91d1d;
  font-weight: bold;
  margin-top: 1em;
}

.testimonial-profilePic {
  width: 50px;
  height: 50px;
  border-radius: 2em;
}

.my_account_right_box table {
  width: 100%;
  float: left;
  border: solid 1px #e5e5e5
}

.my_account_right_box th {
  background-color: #ECFCFF;
  padding: 8px;
}

.my_account_right_box td {
  padding: 8px;
  border-bottom: solid 1px #e5e5e5;
}

.table_ftr_pagination {
  padding-top: 22px;
}

.table_ftr_pagination select {
  font-size: 17px;
  border: solid 1px #e5e5e5;
  padding: 5px;
}

.table_ftr_pagination .col-md-8 ul {
  float: right;
}

.kyc-delete:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .stickercontainer .sticker-img {
    width: 50%;
  }
}

.kycPic {
  width: 50px;
  height: 50px;

}

.myaccount_profile_search_btn {
  width: auto;
  height: auto;
  padding: 5px 20px;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  transition: 0.2s ease-in-out;

}

.package_form_validation {
  color: #cf3131;
}

.add_new_pack {
  background-color: aliceblue;
  border-radius: 40px;
}

.password_fld {
  position: relative;
}

.password_fld .showPassword {
  position: absolute;
  right: 30px;
  top: 36px;
  cursor: pointer;
}

.password_fld input {
  padding-right: 50px;
}

.date-picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 6px;
}

.travel-date-picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 9px;
}


.add_new_photo_travel {
  float: right;
  width: auto;
  border-radius: 103.077px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 13px;
  text-align: center;
  padding: 3px 10px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  /* Prevent content overflow */
}

.add_new_photo_travel .fa-suitcase {
  transition: transform 0.3s ease;
}

.add_new_photo_travel .my_trip_text {
  position: absolute;
  left: 100%;
  /* Place the text outside the button initially */
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
}

.add_new_photo_travel:hover .fa-suitcase {
  transform: translateX(-30px);
}

.add_new_photo_travel:hover .my_trip_text {
  left: 20%;
  /* Adjust the final position as needed */
  opacity: 1;
  visibility: visible;
  padding: 1em;
  font-weight: bolder;
  text-align: center;

}

.add_new_photo_travel:hover {
  width: 100px;

}



.custom-modal-body {
  padding: 0;
}

.referral-img-section {
  padding: 0;
  background-color: #41cfe6ab;
}

.referral-img-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the entire area without stretching */
  display: block;
}

.large-img {
  max-height: 400px;
  /* Adjust this value based on your desired maximum image height */
}

.referral-modal-container {
  width: 100%;
  padding: 30px 37px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.referreral-submitbutton {
  font-family: 'Montserrat';
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  color: #f7fff7;
  align-items: center;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  margin: 10px;
  padding: 7.5px 50px;
  cursor: pointer;
}

.drag-file-area-referral {
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 100%;
  text-align: center;
  border: solid 3px #08aaea;
  /* background-color: #398ed79c; */
}

.drag-file-area-referral span {
  font-size: 26px;
  width: 100%;
  color: #1a1919;
  font-weight: bolder;
}

.drag-file-area-referral .form-check-input {
  width: 16px;
  height: 16px;
}

.drag-file-area-referral .form-check-label {
  font-size:16px;
}

.referral-modal-container .formError {
  align-self: flex-start;
}

.referrer-name-input {
  width: 100%;
  display: flex;
}

.check-referrerbtn {
  text-transform: capitalize;
  border-radius: 24px;
  padding: 8px 7px 9px;
  margin: 4px;
  font-weight: bold;
  font-size: 12px;
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  color: white;
}

.valid-icon {
  color: #20d920;
  margin-top: 11px;
}

.scheduledTripContainer {
  /* max-height: 200px; */
  overflow: auto;
  height: 300px;
}


.requestCount {
  position: absolute;
  right: 0px;
  top: -6px;
  background-color: #2890ce;
  border-radius: 30px;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  color: #fff;
}

.fvrt {
  animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes wave {

  0%,
  100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-20deg);
  }
}

.poke {
  animation: wave 0.5s ease-in-out both;
}


@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.heartbeat {
  animation: heartbeat 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.requestTableHeader thead {
  font-weight: bolder;
  font-size: 15px;
  text-transform: uppercase;
}

.status-select {
  display: flex;
  align-items: center;
  float: right;

}

.status-select button {
  background: linear-gradient(116deg, var(--primary) 24.52%, var(--secondary) 81.69%);
  box-shadow: 7.36264px 5.89011px 18.40659px 0px rgba(68, 174, 255, 0.40);
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 32px;
  transition: 0.2s ease-in-out;
  width: 30px;
  height: 30px;
  border-radius: 16px;
}

.tblProfileContainer {
  display: flex;
  align-items: center;
}

.tablePrfilePic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

/* 
profile skeleton */
.skeleton-container {
  display: flex;
  flex-direction: column;
}

.skeleton-heading {
  width:90%
}

.skeleton-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.skeleton-avatar {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ddd;
  overflow: hidden;
  margin-right: 20px;
}

.skeleton-info {
  flex: 1;
}

.skeleton-line {
  position: relative;
  height: 20px;
  background: #ddd;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
}

.skeleton-line.short {
  width: 60%;
}

.skeleton-line.heading {
  width: 25%;
}

.skeleton-navigation {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.skeleton-nav-item {
  position: relative;
  width: 100px;
  height: 30px;
  background: #ddd;
  margin: 5px;
  border-radius: 4px;
  overflow: hidden;
}

.skeleton-content .skeleton-line {
  width: 100%;
}

.skeleton-content .skeleton-line.short {
  width: 80%;
}

.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  transform: translateX(-100%);
}
.backBtn{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 50px;
  background: #e0f5ff;
  width: 40px;
  height: 40px;
}
.toast-message{
  font-family:"poppinsregular" !important;
  text-decoration: none !important;

}

/* customstyle */

/* .scheduledTripContainer{
  .wrapper {
    /* max-width: 600px;
    margin: 0 auto; */
  /* }  */
  /* ul {
    list-style: none;
    padding: 0;
  }
  
  li + li {
    margin-top: 1rem;
  }
  
  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: aliceblue;
    padding: 1.5rem;
    border-radius: 1rem;
    width: calc(100% - 2rem);
    box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
  }
  
  li::before {
  	
  }
  
  li:nth-child(even) {
    flex-direction: row-reverse;
    background: honeydew;
    margin-right: -2rem;
    margin-left: 2rem;
  }
  
  li:nth-child(even)::before {
    transform: rotateY(180deg);
  }
} */

.premium_div_disabled{
  pointer-events: none;
  opacity: 0.5;
}
.selected_files {
  position: relative;
}

.selected-file-item {
  position: relative; /* Ensure each list item is positioned relative */
  margin-bottom: 3px; /* Optional: add some space between items */
}

.select-file-remove {
  position: absolute;
  right: 35px;
  top: 40%;
  transform: translateY(-50%);
  font-size: small;
  cursor: pointer;
}
