@media (max-width:1400px){
}
@media (max-width:1200px){
    .navbar-nav .nav-link{padding: 8px 7px !important;font-size: 14px;}
    .banner_right_img_sec{max-width: 450px;}
    .banner_left_content_box{max-width: 500px;}
}
@media (max-width:991px){
    .banner_right_obj_2{display: none;}
    .banner_right_obj_1{display: none;}
    .banner_right_obj_6{left: 6px;top: 50px;width: 160px;height: 130px;}
    .banner_right_img_sec{position: relative;right: 0;top: 0;float: right;}
    .banner_main_sec{display: inline-block;padding-top: 120px;height: auto;}
    .user_profile_view_frm_row{flex-wrap: wrap;}
    .user_profile_view_frm_box{width: 100%;}
    .user_profile_view_frm_box_1{width: 100%;}
    .user_profile_view_frm_box_btn {margin-top: 0;}
    .navbar-collapse{background-color: #fff;padding: 10px 0;}
    .notification_ico {display: none;}
    .plan-options {grid-template-columns: 1fr 1fr;}
}
@media (max-width:900px){
    #carrousel .owl-item.big{transform: scale(0.8);}
    #carrousel .owl-carousel .owl-item{height: auto;}
    #carrousel .owl-carousel img{position: static;height: auto;}
    .profile_match_crsl_box_img{height: auto;}
    .best_match_view{margin-top: 0;}
    #carrousel{margin: 0;}
    .footer_copyright_cnt{text-align: center;justify-content: center;  flex-wrap: wrap;}
    .footer_copyright_cnt span{text-align: center;}
    
}
@media (max-width:768px){
.profile_action_btn{max-width: inherit;}
.inner_page_page_name{flex-wrap: wrap;}
.plan:hover {transform: inherit;}
  .plan-options {grid-template-columns: 1fr;}
}